<template lang="pug">
.section.section-cta(v-if="block" :class="`section-cta--${block.theme || 'primary'}`")
  .section-cta__content
    h2.section-cta__title(v-html="block.title")
    |
    p.section-cta__description(v-if="block.description") {{ block.description }}
    |
    .section-cta__description.html(v-if="block.descriptionHTML" v-html="block.descriptionHTML")
    |
    .section-cta__button
      button(
        class="btn btn-secondary"
        v-if="isDownload"
        @click="openModal()"

      )
        IconDownload
        //- @click="downloadPDF('Sponsor Licence Guide.pdf')"
        |
        span Download
      |
      NbButton(
        v-else
        :button="block.button"
      )
  |
  ImageTmp.section-cta__image(:image="block.image")
  |
  dialog.modal(ref="myModal")
    .modal-box
      ZohoFormSLGuide(v-if="isModalOpen")
    |
    form.modal-backdrop(method="dialog")
      button close
</template>

<script setup lang="ts">
import type { TBlock } from "@/models/block";
import { IconDownload } from '@tabler/icons-vue';

interface IProps {
  block?: TBlock | null;
}

const props = withDefaults(defineProps<IProps>(), {
  block: null,
});

const isDownload = computed(() => props?.block?.button?.label === "Download" || props?.block?.button?.label === "Download Now");

const downloadPDF = (fileName: string) => {
  const fileUrl = `/${fileName}`;
  const link = document.createElement('a');
  link.href = fileUrl;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const myModal = ref<HTMLDialogElement | null>(null);
const isModalOpen = ref(false);

const openModal = () => {
  isModalOpen.value = true;
  myModal.value?.showModal();
};

const closeModal = () => {
  isModalOpen.value = false;
  myModal.value?.close();
};
</script>

<style lang="sass" scoped>
.section-cta
  background-position: center
  background-size: cover
  background-repeat: no-repeat
  padding-top: 72px
  padding-bottom: 72px
  align-items: center

  h2, p
    color: var(--tealgreen-25, #E3F7F1)

  &--primary
    background-image: url(/assets/images/background/CTADarkGreen.webp)

    h2, p
      color: #FFF

    & :deep(.html)
      color: #FFF

  &--secondary
    background-image: url(/assets/images/background/CTALightGreen.webp)

    h2, p
      color: #FFF

    & :deep(.html)
      color: #FFF

  &--pale_green
    background-image: url(/assets/images/background/CTAPaleGreen.webp)

    h2, p
      color: var(--deep-teal-800, #2C403A)

    & :deep(.html)
      color: var(--deep-teal-800, #2C403A)

  &__content
    grid-column-start: 1
    grid-column-end: 6

  &__title
    margin-bottom: 24px
    // max-width: 360px

  &__description
    margin-bottom: 48px
    // max-width: 360px
    font-size: 18px
    font-style: normal
    font-weight: 500
    line-height: 26px

  &__image
    grid-column-start: 6
    grid-column-end: 13
    width: 100%
    height: auto
    aspect-ratio: 662 / 410
    object-fit: cover
    border-radius: 16px

@media (max-width: 768px)
  .section-cta
    grid-template-rows: auto auto

    &__title
      width: auto

    &__description
      width: auto

    &__content
      grid-column-start: 1
      grid-column-end: 13
      grid-row-start: 2

    &__image
      grid-column-start: 1
      grid-column-end: 13
      height: 220px
      grid-row-start: 1
      margin-bottom: 32px
</style>